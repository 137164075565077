<!-- 请选择合作服务商类型弹窗 -->
<template>
  <div>
    <el-dialog
        width="40%"
        center
        :visible="visible"
        :lock-scroll="false"
        :destroy-on-close="true"
        custom-class="ele-dialog-form"
        :title="isUpdate?'选择服务商':'选择服务商'"
        @update:visible="updateVisible">
      <el-form
          ref="form"
          :model="form"
          label-width="100px">

        <el-row :gutter="15" style="display: flex;align-items: center;">
          <el-col :span="20">
            <el-form-item label="服务商:" style="margin-bottom: 0!important;">
              <el-input
                  clearable
                  v-model="form.facilitator_name"
                  placeholder="请输入服务商名称"/>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-button class="custom-button" style="background: #31334B!important;border-color: #31334B!important;color: #FFFFFF!important;">
              <!--el-icon-devops 是自己命名的-->
              <i class="el-icon-sousuo" />
              <span style="vertical-align: middle">搜索</span>
            </el-button>
          </el-col>
        </el-row>

        <div style="margin-top: 20px;margin-bottom: 20px;">
          <my-table
              ref="myTable"
              :columns="columns"
              :tableData="List"
              :headerCellStyle="headerCellStyle"
              :total="total"
              :customsFromWhere="where"
              :loading="loading1"
              @select="select"
              @ChangeSize="ChangeSize"
              @currentChange="currentChange"
          >
          </my-table>
        </div>


      </el-form>

      <div slot="footer">
        <el-button
            @click="updateVisible(false)">取消
        </el-button>
        <el-button
            style="margin-left: 20px;"
            type="primary"
            :loading="loading"
            @click="save">确定
        </el-button>
      </div>
    </el-dialog>

  </div>

</template>
<script>
// 引入的接口
import {
  getfacilitatorApi,
} from '@/api/custom'
export default {
  name: '',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({status: 1}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading1:true,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true
        },
        {
          label: "服务商名称 ",
          prop: "facilitator_name",
          isShow: true
        },
        {
          label: "服务商地址",
          prop: "facilitator_addres",
          isShow: true
        },
      ],


    };
  },
  watch: {
    data() {
      if (this.data) {
        console.log(this.data)
        this.form = Object.assign({}, this.data);
        this.isUpdate = true;
      } else {
        this.form = {};
        this.isUpdate = false;
      }
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.updateVisible(false);
          this.$emit('done');
          this.loading = false;
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },


    // 获取列表
    getList(){
      getfacilitatorApi(this.where).then(async (res) => {
        this.total = res.data.count;
        // this.List = res.data.list;
        this.loading1 = false;
      }).catch((res) => {
        this.$Message.error(res.msg);
        this.loading1 = false;
      })
    },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList()
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name"
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #F7F8FA"
    },

  }
}
</script>

<style scoped lang="scss">
.xuanxianglist{

  .xuanxianglistactive{
    cursor: pointer;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 320px;
    height: 146px;
    font-size: 20px;
    font-weight: 500;
    color: #2d2f47;
    display: inline-block;
    margin: 10px;
  }
  .xuanxianglistactive1{
    color: #ff9b05;
  }

  //最后一个
  //.xuanxianglistactive:last-child{
  //  margin-right: 0;
  //}

}
</style>
